@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "Proxima Nova";
  src: url(./fonts/ProximaNova-Regular.ttf);
  /* font-weight: bolder; */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #040303 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .borderImg {
  border: 1px solid;

  border-image-source: linear-gradient(
      89.92deg,
      rgba(240, 185, 11, 0.5) 0.08%,
      rgba(240, 185, 11, 0) 99.93%
    ),
    linear-gradient(
      89.92deg,
      rgba(240, 185, 11, 0) 0.08%,
      rgba(240, 185, 11, 0.1) 99.93%
    );
  border-image-slice: 1;
}
.borderImg::after {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  border: 10px solid yellow;
  content: "";
  z-index: -1;
  border-radius: 80px;
} */

.borderImg {
  position: relative;
  border: 1px solid rgba(240, 185, 11, 0.5);
}
/* .borderImg::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(
      89.92deg,
      rgba(240, 185, 11, 0.5) 0.08%,
      rgba(240, 185, 11, 0) 99.93%
    ),
    linear-gradient(
      89.92deg,
      rgba(240, 185, 11, 0) 0.08%,
      rgba(240, 185, 11, 0.1) 99.93%
    );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
} */

.borderImgTwo {
  position: relative;
  border: 1px solid rgba(240, 185, 11, 0.5);
}
/* .borderImgTwo::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  z-index: -1;
  padding: 1px;
  background: linear-gradient(
      89.92deg,
      rgba(240, 185, 11, 0.5) 0.08%,
      rgba(240, 185, 11, 0) 99.93%
    ),
    linear-gradient(
      89.92deg,
      rgba(240, 185, 11, 0) 0.08%,
      rgba(240, 185, 11, 0.1) 99.93%
    );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
} */
